<script>
/**
 * Widget Component
 */
export default {
  props: ["statics"],
  data() {
    return {
      statData: [
        {
          title: this.$t("setup.totalusers"),
          icon: "ri-stack-line",
          // value: this.statics.totalUser,
          value: 0,
        },
        {
          title: this.$t("setup.totalcompany"),
          icon: "ri-store-2-line",
          // value: this.statics.totalCompany,
          value: 0,
        },
        {
          title: this.$t("setup.activecards"),
          icon: "ri-briefcase-4-line",
          // value: this.statics.activeCard,
          value: 0,
        },
        {
          title: this.$t("setup.passivecards"),
          icon: "ri-briefcase-4-line",
          // value: this.statics.passiveCard,
          value: 0,
        },
      ],
    };
  },
  created() {
    this.statData[0].value = this.statics.totalUser;
    this.statData[1].value = this.statics.totalCompany;
    this.statData[2].value = this.statics.activeCard;
    this.statData[3].value = this.statics.passiveCard;
  },
};
</script>

<template>
  <div class="row">
    <div class="col-md-3" v-for="(data, index) in statData" :key="index">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">{{ data.title }}</p>
              <h4 class="mb-0">{{ data.value }}</h4>
            </div>
            <div class="text-primary">
              <i :class="`${data.icon} font-size-24`"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>