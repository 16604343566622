<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Swal from "sweetalert2";
import { HTTP } from "@/main-source";

import Stat from "./widget";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Stat,
  },
  data() {
    return {
      title: "Dashboard",
      list: [],
      statics: null,
    };
  },
  created() {
    this.getData();
    this.getStatics();
  },
  methods: {
    getData: function () {
      this.list = [];
      let uri = "users?limit=10";

      HTTP.get(uri)
        .then((result) => {
          this.list = result.data;
          this.xhrRequest = false;
        })
        .catch(() => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: this.$t("setup.gerr"),
            showConfirmButton: false,
            timer: 1500,
          });
          this.xhrRequest = false;
        });
    },
    getStatics() {
      HTTP.get("firms/statics")
        .then((result) => {
          this.statics = result.data;
        })
        .catch(() => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: this.$t("setup.gerr"),
            showConfirmButton: false,
            timer: 1500,
          });
          this.xhrRequest = false;
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title='$t("setup.lastest")'/>
    <div class="row">
      <div class="col-xl-12">
        <Stat :statics="statics" v-if="statics" />
      </div>
      <div class="col-xl-12">
        <PageHeader :title='$t("setup.dashboard")' />
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-editable table-nowrap table-edits">
                <thead>
                  <tr style="cursor: pointer">
                    <th></th>
                    <th>{{ $t("setup.name") }}</th>
                    <th>Link</th>
                    <th class="text-center">{{ $t("setup.view") }}</th>
                    <th>Tel</th>
                    <th>E-Mail</th>
                    <th>{{ $t("setup.verifiedstatus") }}</th>
                    <th>{{ $t("setup.role") }}</th>
                    <th>Firma</th>
                    <th>{{ $t("setup.action") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, key) in list" :key="key">
                    <td style="width: 80px">{{ key + 1 }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ $appDomain + item.shortlink }}</td>
                    <td class="text-center">
                      <span class="badge badge-info">
                        {{ item.pageViewCount }}
                      </span>
                    </td>

                    <td>{{ item.tel }}</td>
                    <td>{{ item.email }}</td>
                    <td>
                      <i
                        class="fas fa-check-circle color-green"
                        v-if="item.isVerified"
                      ></i>
                      <span
                        class="badge color-black"
                        v-if="item.isVerified == true"
                        >{{ $t("setup.verified") }}</span
                      >
                      <span
                        class="badge color-black"
                        v-if="item.isVerified == false"
                        >{{ $t("setup.notyetverified") }}</span
                      >
                    </td>
                    <td>
                      <span
                        class="badge"
                        v-if="item.roleId"
                        :class="[
                          { 'bg-primary': item.roleId == 1 },
                          { 'bg-warning': item.roleId == 2 },
                          { 'bg-info': item.roleId == 3 },
                        ]"
                      >
                        {{ $getRoleName(item.roleId) }}
                      </span>
                    </td>
<td>{{  item["firm.name"] ? item["firm.name"] : item.firmName }}</td>
                    <td style="width: 100px">
                      <router-link
                        class="btn btn-outline-secondary btn-sm edit"
                        :to="{
                          name: 'user-profile',
                          params: {
                            shortlink: item.shortlink,
                          },
                        }"
                      >
                        <i class="fas fa-pencil-alt"></i>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                class="d-block w-100 mt-4 text-center"
                v-if="list.length == 0"
              >
                <i
                  class="
                    mdi mdi-information
                    text-primary
                    me-1
                    align-middle
                    font-size-16
                  "
                ></i>
                {{ $t("setup.nouser") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>