var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.$t("setup.lastest")}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[(_vm.statics)?_c('Stat',{attrs:{"statics":_vm.statics}}):_vm._e()],1),_c('div',{staticClass:"col-xl-12"},[_c('PageHeader',{attrs:{"title":_vm.$t("setup.dashboard")}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-editable table-nowrap table-edits"},[_c('thead',[_c('tr',{staticStyle:{"cursor":"pointer"}},[_c('th'),_c('th',[_vm._v(_vm._s(_vm.$t("setup.name")))]),_c('th',[_vm._v("Link")]),_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("setup.view")))]),_c('th',[_vm._v("Tel")]),_c('th',[_vm._v("E-Mail")]),_c('th',[_vm._v(_vm._s(_vm.$t("setup.verifiedstatus")))]),_c('th',[_vm._v(_vm._s(_vm.$t("setup.role")))]),_c('th',[_vm._v("Firma")]),_c('th',[_vm._v(_vm._s(_vm.$t("setup.action")))])])]),_c('tbody',_vm._l((_vm.list),function(item,key){return _c('tr',{key:key},[_c('td',{staticStyle:{"width":"80px"}},[_vm._v(_vm._s(key + 1))]),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(_vm.$appDomain + item.shortlink))]),_c('td',{staticClass:"text-center"},[_c('span',{staticClass:"badge badge-info"},[_vm._v(" "+_vm._s(item.pageViewCount)+" ")])]),_c('td',[_vm._v(_vm._s(item.tel))]),_c('td',[_vm._v(_vm._s(item.email))]),_c('td',[(item.isVerified)?_c('i',{staticClass:"fas fa-check-circle color-green"}):_vm._e(),(item.isVerified == true)?_c('span',{staticClass:"badge color-black"},[_vm._v(_vm._s(_vm.$t("setup.verified")))]):_vm._e(),(item.isVerified == false)?_c('span',{staticClass:"badge color-black"},[_vm._v(_vm._s(_vm.$t("setup.notyetverified")))]):_vm._e()]),_c('td',[(item.roleId)?_c('span',{staticClass:"badge",class:[
                          { 'bg-primary': item.roleId == 1 },
                          { 'bg-warning': item.roleId == 2 },
                          { 'bg-info': item.roleId == 3 } ]},[_vm._v(" "+_vm._s(_vm.$getRoleName(item.roleId))+" ")]):_vm._e()]),_c('td',[_vm._v(_vm._s(item["firm.name"] ? item["firm.name"] : item.firmName))]),_c('td',{staticStyle:{"width":"100px"}},[_c('router-link',{staticClass:"btn btn-outline-secondary btn-sm edit",attrs:{"to":{
                          name: 'user-profile',
                          params: {
                            shortlink: item.shortlink,
                          },
                        }}},[_c('i',{staticClass:"fas fa-pencil-alt"})])],1)])}),0)]),(_vm.list.length == 0)?_c('div',{staticClass:"d-block w-100 mt-4 text-center"},[_c('i',{staticClass:"\n                    mdi mdi-information\n                    text-primary\n                    me-1\n                    align-middle\n                    font-size-16\n                  "}),_vm._v(" "+_vm._s(_vm.$t("setup.nouser"))+" ")]):_vm._e()])])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }